<template>
  <!-- eslint-disable-next-line vuejs-accessibility/no-static-element-interactions -- Обработчик всех enter нажатий. -->
  <form @keydown.enter.exact="(event) => onEnterKeydownHandler(event)">
    <slot />
  </form>
</template>

<script lang="ts" setup>
interface Props {
  hasEnterSubmit?: boolean;
}

const { hasEnterSubmit } = defineProps<Props>();

defineSlots<{
  default: [];
}>();

const onEnterKeydownHandler = (event: KeyboardEvent) => {
  const target = event.target as HTMLElement | null;

  if (target && target.tagName !== "TEXTAREA" && !hasEnterSubmit) {
    event.preventDefault();
  }
};
</script>
